import React from "react";
import HomeHero from "./HomeSection/HomeHero";
import HomeAbout from "./HomeSection/HomeAbout";
// import HomeData from './HomeSection/HomeData'
import HomeExclusive from "./HomeSection/HomeExclusive";
import HomeUpcoming from "./HomeSection/HomeUpcoming";
import HomeWhy from "./HomeSection/HomeWhy";
import HomeLeader from "./HomeSection/HomeLeader";
import HomeBlogs from "../Blogs/HomeBlogs";
// import HomeNews from "../News/HomeNews";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Real Estate Services | Ekakshar Buildtech</title>
        <meta name="title" content="Home | Ekakshar Buildtech" />
        <meta
          name="description"
          content="Ekakshar Buildtech offers reliable real estate services in Noida, specializing in buying, selling, and renting properties. Expert guidance for seamless deals!"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://ekaksharbuildtech.com/" />

        <meta
          property="og:title"
          content="Ekakshar Buildtech | Your Trusted Real Estate Partner"
        />
        <meta
          property="og:description"
          content="Discover your dream property with Ekakshar Buildtech, a leading real estate company committed to exceptional service and quality homes that suit your lifestyle and investment goals."
        />
        <meta
          property="og:image"
          content="https://ekaksharbuildtech.com/navlogo.webp"
        />
        <meta property="og:url" content="https://ekaksharbuildtech.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Ekakshar Buildtech" />

        {/* Structured Data - Corporation */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Corporation",
            name: "Ekakshar Buildtech",
            url: "https://ekaksharbuildtech.com/",
            logo: "https://ekaksharbuildtech.com/navlogo.webp",
            address: {
              "@type": "PostalAddress",
              streetAddress: "7th Floor, Plot No-59, Riana Perito Tower , Sector-136",
              addressLocality: "Noida",
              addressRegion: "Uttar Pradesh",
              postalCode: "201305",
              addressCountry: "IN",
            },
            description:
              "Ekakshar Buildtech is a leading real estate company specializing in residential and commercial properties. We prioritize sustainability, innovation, and exceptional service to deliver high-quality projects that blend modern design and functionality.",
            founder: {
              "@type": "Person",
              name: "Mr. Rohit Khari",
            },
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+91 70420 05600",
              contactType: "reservations",
              contactOption: "TollFree",
              areaServed: "IN",
              availableLanguage: "en",
            },
            sameAs: [
              "https://www.facebook.com/EkaksharBuildtech",
              "https://www.instagram.com/ekakshar_buildtech/",
              "https://www.linkedin.com/company/ekaksharbuildtech/",
              "https://www.youtube.com/@EkaksharBuildtech/",
              "https://x.com/EkaksharBuilder",
            ],
          })}
        </script>

        {/* Structured Data - BreadcrumbList */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "About Us",
                item: "https://ekaksharbuildtech.com/about",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Contact",
                item: "https://ekaksharbuildtech.com/Contact",
              },
              {
                "@type": "ListItem",
                position: 3,
                name: "Blogs",
                item: "https://ekaksharbuildtech.com/Our-Blogs",
              },
              {
                "@type": "ListItem",
                position: 4,
                name: "Shree Shyam Aerocity",
                item: "https://ekaksharbuildtech.com/Shree-Shyam-Aerocity",
              },
              {
                "@type": "ListItem",
                position: 5,
                name: "Shree Shyam Township",
                item: "https://ekaksharbuildtech.com/Shree-Shyam-Township",
              },
              {
                "@type": "ListItem",
                position: 6,
                name: "Bliss Valley",
                item: "https://ekaksharbuildtech.com/Bliss-Valley",
              },
            ],
          })}
        </script>
      </Helmet>

      {/* Sections */}
      <HomeHero />
      <HomeAbout />
      {/* <HomeData/> */}
      <HomeExclusive />
      <HomeUpcoming />
      <HomeWhy />
      <HomeLeader />
      {/* <HomeNews/> */}
      <HomeBlogs />
    </div>
  );
};

export default Home;
