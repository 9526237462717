import React from 'react'
import "./Gallery.css"
const Gallerysec1 = () => {
  return (
    <div className='galleryherosec'>
        <h1 className='aboutherotext'>Gallery</h1>
    </div>
  )
}

export default Gallerysec1