import React, {  useEffect } from 'react';
import './About.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
const historyData = [
  {
    imgSrc: './Mission.webp',
    text:
      'Our goal is to build exceptional real estate projects that benefit our partners, clients, and communities for a long time. We are dedicated to going above and above with exceptional, customer-focused solutions that put sustainability, design, and usefulness first. By embracing innovation and keeping up with industry advancements, we want to create spaces that improve and uplift lives. We are committed to executing projects that promote a better future in addition to meeting current needs.',
  },
  {
    imgSrc: './vission.webp',
    text:
      'Our goal at Ekakshar Buildtech Pvt. Ltd. is to lead the real estate sector by developing cutting-edge, environmentally friendly, and top-notch living environments. In order to foster progress, wealth, and well-being for future generations, we work to alter communities with developments that combine contemporary architecture with environmental responsibility. Every project is driven by our emphasis on integrity, quality, and client pleasure, which guarantees that every area we create satisfies strict requirements and enhances the lives of its occupants.',
  },
  {
    imgSrc: './value.webp',
    text:
      "The core values we follow at Ekakshar Buildtech influence our business practices. Honesty and trust guide everything we do and help us build transparency in every interaction. Quality is our top priority in all projects, ensuring that our designs are exceptional and long-lasting. Our passion for new ideas encourages us to explore fresh concepts and innovations, and our commitment to customer focus ensures that we always put our clients' needs first. Our values guide us as we create outstanding real estate projects that benefit the communities we serve. We are always looking for ways to improve and adapt our programs to keep up with the changing needs of the market.",
  },
];

const Aboutsec4 = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500, 
      easing: 'ease-in-out',
    });
  }, []);
  return (
    <div className="exclusivediv1">
      <div className="container">
        <h3 className="abouthead center ">Our Commitment</h3>
        <div className=" mt-3">
          {historyData.map((item, index) => (
            <React.Fragment key={index}>
              <div className="">
                {index % 2 !== 0 ? (
                  <div className="twogrid2">
                    <div>
                      <img src={item.imgSrc} className="historyimg" alt="History" loading="lazy" />
                    </div>
                    <div className='divider'></div>
                    <div className="padd ">
                      <p data-aos="zoom-in" className="missiontext">{item.text}</p></div>
                    </div>
                ) : (
                  <div className="twogrid2 flexii">
                    <div className="padd ">
                      <p data-aos="zoom-in" className="missiontext ">{item.text}</p>
                    </div>
                    <div className='divider'></div>
                    <div>
                      <img src={item.imgSrc} className="historyimg" alt="History" loading="lazy" />
                    </div>
                  </div>
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Aboutsec4;
