import React from "react";
import FaqHero from "./FaqHero";
import FaqQuestion from "./FaqQuestion";
import FaqPayment from "./FaqPayment";
import FaqAdvantage from "./FaqAdvantage";
import { Helmet } from "react-helmet";

const Faq = () => {
  return (
    <div>
      <Helmet>
        <title>FAQ | Top Real Estate Services in Noida by Ekakshar Buildtech</title>
        <meta
          name="description"
          content="Get answers to your questions about Ekakshar Buildtech and our top real estate services in Noida. Learn more about our expert offerings."
        />
      
         <meta name="robots" content="index, follow"/>
         <link rel="canonical" href="https://ekaksharbuildtech.com/faqs" />
      </Helmet>
      <FaqHero />
      <FaqQuestion />
      <FaqPayment />
      <FaqAdvantage />
    </div>
  );
};

export default Faq;
