import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes } from "react-icons/fa";
import './Slideout.css'

const SlideoutPage = ({ isOpen, onClose }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setIsModalOpen(true);
          document.body.style.overflow = 'hidden'; 
        }, 2000);
    
        return () => {
          clearTimeout(timeoutId);
          document.body.style.overflow = ''; 
        };
      }, []);
    
      const handleClose = () => {
        setIsModalOpen(false);
        document.body.style.overflow = ''; 
      };
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      overlayClassName="modal-overlay"
      className="modal-content slide-up"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div className='popback'>
       <div className='popup-grid'>
        <div className='d-flex justify-content-between'>
<p className='popcontent'>We use cookies on our website to provide you with a more personalized digital experience. To learn more about how we use cookies and how you can change your cookie settings, please refer to the following. <a href='/privacystatement' target="_blank" rel="noopener noreferrer"><b>Privacy Statement</b></a> </p>
<FaTimes className="closebutt1 "  onClick={handleClose} />
        </div>
        <div className='popbuttn_div'>
        <div>
        <button className='popup_btn'  onClick={handleClose}>Accept All</button>
        <a href='/privacystatement' target="_blank" rel="noopener noreferrer">
        <button className='popup_btn'>Read More</button></a>
        </div>
        <div className='closediv'>
        <FaTimes className="closebutt"  onClick={handleClose} />
      </div>
      </div>
       </div>
       </div>
      </Modal>
  )
}

export default SlideoutPage