import React from 'react'
import "./Blogs.css"
const Blogssec1 = () => {
  return (
    <div className='blogsheroback contentbefore'>
      { /*<h1 className='aboutherotext'>BLOGS</h1>*/}
    </div>
  )
}

export default Blogssec1