import React from 'react'
import '../Project.css'
import data from '../Project.json'
import { FaLocationDot } from "react-icons/fa6";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import { FaFlag } from "react-icons/fa";
const RecentProject = () => {
  return (
    <div className='upcoming_backdiv'>
      <div className='backdiv'>
          <h2 className='abouthead_white'>UPCOMING PROJECT</h2>
          <div className=''>
          <div className=' mt-4'>
            <Swiper
              spaceBetween={20}  
              slidesPerView={3} 
              loop={true} 
              autoplay={{
                delay: 2000,  
                disableOnInteraction: false,  
              }}
              breakpoints={{
                1024: {
                  slidesPerView: 3, 
                },
                768: {
                  slidesPerView: 2,  
                },
                480: {
                  slidesPerView: 1,  // 1 slide on mobile
                },
                320: {
                  slidesPerView: 1,  // Ensure 1 slide on smaller devices
                },
              }}
              modules={[Autoplay]}  
              className="mySwiper"
            >
              {data.RecentProject.map((NewData, index) => (
                <SwiperSlide key={index}>
                  <a href={NewData.link}>
                    <div className='exlapcard1'>
                      <img className='lapeximg' src={NewData.img} alt='pro' loading='lazy' />
                      <div className='cardheight'><p className='cardname'>{NewData.projname }</p>
                      <hr className='homehr' />
                      <div className='between1'>
                        <p className='onrequest'><FaLocationDot />{NewData.address}</p>
                        <p className='cardtype'><FaFlag /> {NewData.type}</p></div>
                      </div>
                    </div>
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecentProject;
