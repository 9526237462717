import React from 'react'
import '../Project.css'
import data from '../Project.json'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';

const ProjectSection1 = () => {
  return (
    <div className='projsec_maindiv'>
    <div className='backdiv'>
         <h1 className='abouthead1'>Why You Should Work With Us?</h1>
         <Swiper
      slidesPerView={3}
      spaceBetween={50}
      modules={[Autoplay]}
      loop={true}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      breakpoints={{
        1024: {
          slidesPerView: 3,
          autoplay: false, 
        },
        992: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        },
      }}
    >
       <div className='projsection_grid mt-5'>
                   {data.Work.map((NewData, index) => {
          return(
            <SwiperSlide>
          <div key={index}>
         
            <div className='d-flex align-items-center justify-content-center'>
              <img className='work_icons' src={NewData.img} alt='dd'/>
            </div>
            <h4 className='w_heading'>{NewData.head}</h4>
            <p className='aboutext'>{NewData.content}</p>
          
          </div>
          </SwiperSlide>
  )
})}

       
         </div>
         </Swiper>
    </div>
    </div>
  )
}

export default ProjectSection1