import React,{useEffect} from 'react'
import "./Inner.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
const Shreeshyam = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500, 
      easing: 'ease-in-out',
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>Plots Near Khatu Shyam Mandir | Ekakshar Buildtech </title>
        <meta
          name="description"
          content="Ekakshar Buildtech  provides expert real estate services, offering residential plots near Khatu Shyam Mandir, domestic airport, and Sikar city in Rajasthan."
        />
      <meta property="og:title" content="Shree Shyam Township | Premier Real Estate by Ekakshar Buildtech" />
        <meta property="og:description" content="Discover Shree Shyam Township, an exceptional residential project by Ekakshar Buildtech. Experience modern living with premium amenities, spacious designs, and an ideal location." />
        <meta property="og:image" content="https://ekaksharbuildtech.com/innerproject1.webp" />
        <meta property="og:url" content="https://ekaksharbuildtech.com/Shree-Shyam-Township" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Ekakshar Buildtech" />
         <meta name="robots" content="index, follow"/>
        <link rel="canonical" href="https://ekaksharbuildtech.com/Shree-Shyam-Township" />
      </Helmet>
    <div className='shreeshyamheroback '>
    </div>
    <div className='container twogrid flexii mt-4 '>
    <div className='mt-3' data-aos="zoom-in" >
    <h1 className='firstprojecthead'>About The Project</h1>
        <p className='aboutext'><b>Shri Shyam Township</b> by Ekakshar Buildtech is an expansive residential and commercial development spanning 500 bighas of prime Rajasthani land, offering plots ranging from 100 square yards to 2000 square yards. This township caters to diverse needs, whether you're looking for a residential plot, a farmhouse retreat, or a commercial space, all with modern amenities. It features beautifully landscaped parks, a well-maintained water supply, well-paved roads, 24/7 street lighting, and a clubhouse for relaxation. A small stadium is available for sports enthusiasts, and the wide main and internal roads ensure easy accessibility. Strategically located near the revered <b>Khatu Shyam Mandir</b>, the township offers a peaceful environment with close proximity to spiritual landmarks, making it an ideal choice for those seeking a blend of calm and connectivity. With its combination of serene living and modern infrastructure, <b>Shri Shyam Township</b> is the perfect place for a home, getaway, or business opportunity.<b>Buy plots near Khatu Shyam Mandir</b> and enjoy the harmony of nature, convenience, and culture.</p>
        </div>
    <div className='mt-3'>
        <img src='/townshipsitelayout1.jpg' className='projectimg1' alt='projectimg'/>
    </div>
    </div>
    <div className='container twogrid '>
    <div className='mt-3'>
        <img src='/townshipimg2.jpg' className='projectimg1' alt='projectimg'/>
    </div>
    <div className='mt-3' data-aos="zoom-in" >
        <h2 className='firstprojecthead'>Transforming Visions into Reality</h2>
        <p className='aboutext'>Ekakshar Buildtech's residential and commercial project, Shri Shyam Township, is situated on 500 bighas of prime Rajasthani land. Residential, farmhouse, and commercial plots are among the many types of plots available for the project; sizes range from 100 square yards to 2000 square yards. With features like parks, a water supply, well-paved roads, street lighting, and a clubhouse for residents to relax in, the township is designed to offer a comfortable and contemporary living environment. It also has a small stadium for sports lovers, along with a 40-foot-wide main road and 30-foot-wide roads inside for easy access and smooth movement around the township. Whether you want a quiet home, a farmhouse getaway, or a place for business, Shri Shyam Township provides a well-balanced and welcoming environment for everyone.</p>
    </div>
    </div>
    <div className='upcoming_backdiv mt-5' >
        <h4 className='whitetext'>Prime Location Benefits</h4>
        <div className="mobile-swiper container">
          <Swiper
          modules={Autoplay}
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false,  }}
          >
            <SwiperSlide><img src='/Aerocity-info4.webp' className='projectswiperimg' alt='projectimg'/></SwiperSlide>
            <SwiperSlide><img src='/Aerocity-info5.webp' className='projectswiperimg' alt='projectimg'/></SwiperSlide>
            <SwiperSlide><img src='/Aerocity-info6.webp' className='projectswiperimg' alt='projectimg'/></SwiperSlide>
          </Swiper>
        </div>
        <div className='threegrid container mt-3'>
            <img src='/Aerocity-info4.webp' className='projectswiperimg' alt='projectimg'/>
            <img src='/Aerocity-info5.webp' className='projectswiperimg' alt='projectimg'/>
            <img src='/Aerocity-info6.webp' className='projectswiperimg' alt='projectimg'/>
        </div>
    </div>
    <div className='blueback'>
    <div className='container'>
        <h3 className='projecthead'>Project Featuring</h3>
    <ul class="custom-list" data-aos="fade-up">
  <li>Located near the renowned Hidamba Mata Devi Temple, offering spiritual proximity.</li>
  <li>Located close to the famous Khatu Shyam Temple, a well-known religious and cultural landmark.</li>
  <li>Positioned along State Highway 113, ensuring excellent connectivity and easy access.</li>
  <li>Situated near the scenic Aravalli Hills, offering stunning natural views and a peaceful atmosphere.</li>
  <li>Located near the upcoming airport, enhancing future accessibility and convenience for travellers.</li>
  <li>A short distance from Baya Railway Station, making it well-connected to major cities. </li>
  <li>Surrounded by religious, cultural, and natural attractions, enriching the community experience.</li>
  <li>Perfectly located for spiritual, recreational, and practical convenience, making it ideal for both living and investment.</li>
</ul>
        </div>
        </div>
        <div className=' mt-5' >
        <h4 className='blacktext'>Special Features</h4>
        <div className="mobile-swiper container">
          <Swiper
          modules={Autoplay}
            spaceBetween={10}
            slidesPerView={2}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false,  }}
          >
            <SwiperSlide><div className="carding">
   <div className="card1" >
    <p className="small">Dedicated paths for jogging, walking, and biking.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div></SwiperSlide>
            <SwiperSlide><div className="carding">
   <div className="card1" >
    <p className="small">Social spaces for community gatherings and events.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div></SwiperSlide>
            <SwiperSlide><div className="carding">
   <div className="card1" >
    <p className="small">Spacious parking areas with options for residents and visitors.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div></SwiperSlide>
            <SwiperSlide><div className="carding">
   <div className="card1" > 
    <p className="small">On-site spa offering relaxation treatments and wellness services.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className='fourgrid1 container mt-3'>
<div className="carding">
   <div className="card1" >
    <p className="small">Dedicated paths for jogging, walking, and biking.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div>
<div className="carding">
   <div className="card1" >
    <p className="small">Social spaces for community gatherings and events.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div>
<div className="carding">
   <div className="card1" >
    <p className="small">Spacious parking areas with options for residents and visitors.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div>
<div className="carding">
   <div className="card1" > 
    <p className="small">On-site spa offering relaxation treatments and wellness services.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div>
        </div>
    </div>
    </div>
  )
}

export default Shreeshyam