import React from "react";
import "../Home.css";
import data from "../Home.json";

const HomeAbout = () => {
  return (
    <>
      <div className="homeabout_div">
        <div className="backdiv">
          <h2 className="abouthead1">About Ekakshar</h2>

          <div className="home_abtsec">
            <div className="">
              <img
                className="abtimg_div1 "
                src="/eventsimg1.webp"
                loading="lazy"
                alt="rohitsir"
              />
             
            </div>
            {data.AboutData.map((NewData, index) => {
              return (
                <div key={index}>
                  {/* <h4 className="headss1">{NewData.Head}</h4> */}
                  {/* <h5 className='head2'>{NewData.subhead}</h5> */}
                  <p className="aboutext" >{NewData.content}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeAbout;
