import React,{useEffect} from 'react'
import "./Inner.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";
const Aerocity = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500, 
      easing: 'ease-in-out',
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>Buy Plots near Domestic Airport | Ekakshar Buildtech</title>
        <meta
          name="description"
          content="Buy premium residential plots near the Domestic Airport and Khatu Shyam Temple with Ekakshar Buildtech  Invest in a prime location for growth and convenience."
        />
        <meta property="og:title" content="Shree Shyam Aerocity | Premium Real Estate by Ekakshar Buildtech" />
        <meta property="og:description" content="Explore Shree Shyam Aerocity, an exceptional real estate project by Ekakshar Buildtech. Offering modern amenities, strategic location, and unparalleled comfort for a superior living experience." />
        <meta property="og:image" content="https://ekaksharbuildtech.com/innerproject2" />
        <meta property="og:url" content="https://ekaksharbuildtech.com/Shree-Shyam-Aerocity" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Ekakshar Buildtech" />
           <meta name="robots" content="index, follow"/>
        <link rel="canonical" href="https://ekaksharbuildtech.com/Shree-Shyam-Aerocity" />
      </Helmet>
    <div className='shreeshyamheroback1'>
    </div>
    <div className='container twogrid flexii '>
    <div className='mt-3' data-aos="zoom-in" >
    <h1 className='firstprojecthead'>About The Project</h1>
        <p className='aboutext'><b>Shri Shyam Aerocity</b> in Maganpura, near the revered Khatu Shyam Ji Temple, offers a perfect blend of spiritual serenity and modern living. Just a short distance from the temple and easily accessible via State Highway 113, this residential project features <b>plots starting at 100 square yards</b>, ideal for building your dream home. The gated community provides modern amenities such as secure entrances, well-maintained roads, reliable utilities, and 24/7 security. With the upcoming <b>Khatu Shyam Ji Airport</b> and <b>Baya Railway Station</b> enhancing connectivity, residents will enjoy easy access to transportation. Whether you're looking for a peaceful retreat or a well-connected investment, Shri Shyam Aerocity offers the best of both worlds. <b>Buy plots near the Domestic Airport</b> for a serene lifestyle with great growth potential in a vibrant, developing area. With its blend of spirituality, convenience, and future prospects, this is the ideal place for those seeking both peace and prosperity.</p>
        </div>
    <div className='mt-3'>
        <img src='/Aerocitysitelayout1.jpg' className='projectimg1' alt='projectimg'/>
    </div>
    </div>
    <div className='container twogrid '>
    <div className='mt-3'>
        <img src='/Aerocitysitelayout.jpg' className='projectimg1' alt='projectimg'/>
    </div>
    <div className='mt-3' data-aos="zoom-in" >
        <h2 className='firstprojecthead'>Where Dreams Find a Home</h2>
        <p className='aboutext'>Nestled in the heart of spiritual Rajasthan, Sri Shyam Aerocity offers an exceptional opportunity for families looking for a serene and promising residential community. Located on Dataram Garh Road, right opposite the upcoming airport and in close proximity to the revered Khatu Shyam Ji Temple, this premium development is poised to become one of the region's most sought-after addresses. With nearby upcoming infrastructure like Baya Railway Station and enhanced road connectivity, Sri Shyam Aerocity is not just a home but a smart investment for the future.
        </p>
    </div>
    </div>
    <div className='upcoming_backdiv mt-5' >
        <h4 className='whitetext'>Prime Location Benefits</h4>
        <div className="mobile-swiper container">
          <Swiper
          modules={Autoplay}
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false,  }}
          >
            <SwiperSlide><img src='/Aerocity-info.webp' className='projectswiperimg' alt='projectimg'/></SwiperSlide>
            <SwiperSlide><img src='/Aerocity-info2.webp' className='projectswiperimg' alt='projectimg'/></SwiperSlide>
            <SwiperSlide><img src='/Aerocity-info3.webp' className='projectswiperimg' alt='projectimg'/></SwiperSlide>
          </Swiper>
        </div>
        <div className='threegrid container mt-3'>
            <img src='/Aerocity-info.webp' className='projectswiperimg' alt='projectimg'/>
            <img src='/Aerocity-info2.webp' className='projectswiperimg' alt='projectimg'/>
            <img src='/Aerocity-info3.webp' className='projectswiperimg' alt='projectimg'/>
        </div>
    </div>
    <div className='blueback'>
    <div className='container'>
        <h3 className='projecthead'>Project Featuring</h3>
    <ul class="custom-list" data-aos="fade-up">
  <li>Reliable and uninterrupted electricity supply to all plots.</li>
  <li>Well-lit streets for enhanced safety and convenience at night.</li>
  <li>Spacious 30 ft to 40 ft wide roads for easy movement and better connectivity.</li>
  <li>Round-the-clock water availability to ensure comfort and convenience.</li>
  <li>Continuous security surveillance, providing a safe and secure environment for all residents.</li>
  <li>A secure, gated community with controlled access for added privacy and safety.</li>
  <li>Situated on Dataram Garh Road, ensuring excellent connectivity to surrounding areas.</li>
  <li>Close to Khatu Shyam Ji Temple, Khatu Shyam Ji Airport, Baya Railway Station, and State Highway 113, offering easy access to transport and spiritual landmarks.</li>
</ul>
        </div>
        </div>
        <div className=' mt-5' >
        <h4 className='blacktext'>Special Features</h4>
        <div className="mobile-swiper container">
          <Swiper
          modules={Autoplay}
            spaceBetween={10}
            slidesPerView={2}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false,  }}
          >
            <SwiperSlide><div className="carding">
   <div className="card1" >
    <p className="small">Expansive green spaces for relaxation and outdoor activities.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div></SwiperSlide>
            <SwiperSlide><div className="carding">
   <div className="card1" >
    <p className="small">Leisure and lap pools for residents and guests.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div></SwiperSlide>
            <SwiperSlide><div className="carding">
   <div className="card1" >
    <p className="small">Modern gym with a variety of exercise equipment.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div></SwiperSlide>
            <SwiperSlide><div className="carding">
   <div className="card1" > 
    <p className="small">Safe playgrounds designed for children's enjoyment.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className='fourgrid1 container mt-3'>
<div className="carding">
   <div className="card1" >
    <p className="small">Expansive green spaces for relaxation and outdoor activities.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div>
<div className="carding">
   <div className="card1" >
    <p className="small">Leisure and lap pools for residents and guests.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div>
<div className="carding">
   <div className="card1" >
    <p className="small">Modern gym with a variety of exercise equipment.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div>
<div className="carding">
   <div className="card1" > 
    <p className="small">Safe playgrounds designed for children's enjoyment.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div>
        </div>
    </div>
    </div>
  )
}

export default Aerocity