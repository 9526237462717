import React from 'react'
import Gallerysec1 from './Gallerysec1'
import Gallerysec2 from './Gallerysec2'

const Gallery = () => {
  return (
    <div>
        <Gallerysec1/>
        <Gallerysec2/>
    </div>
  )
}

export default Gallery