import React from 'react'
import './About.css'
const Aboutsec1 = () => {
  return (
    <div>
    <div className='aboutherosec'>
    { /*<h1 className='aboutherotext'>ABOUT US</h1>*/}
    </div>
    </div>
  )
}

export default Aboutsec1