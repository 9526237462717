import React, { useState, useEffect } from 'react';
import '../Home.css';

const HomeWhy = () => {
  const images = ['./why_img1.webp', './why_img2.webp', './why_img3.webp'];
  const [currentImage, setCurrentImage] = useState(images[0]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % images.length;
        setCurrentImage(images[nextIndex]);
        return nextIndex;
      });
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='whymain_div backdiv'>
      <h2 className='abouthead1 '>Why Ekakshar</h2>
    <div className='twogrid'> 
      <div>      

        <div className='relative'>
          <div className='d-flex'>
            <div className={`num_circle ${activeIndex === 0 ? 'active' : ''}`}>
              <p className='num_text'>1</p>
            
            </div>
           
            <div className='text-content'>
              <h3 className='whyheading1'>Real Estate Deals</h3>
              <p className='why_text1'>Unlock Unbeatable Real Estate Deals: Discover at Unbelievable Prices!</p>
            </div>
          </div>
        </div>

        <div className='relative'>
          <div className='d-flex'>
            <div className={`num_circle ${activeIndex === 1 ? 'active' : ''}`}>
              <p className='num_text'>2</p>
           
            </div>
          
            <div className='text-content'>
              <h3 className='whyheading1'>Guaranteed Success</h3>
              <p className='why_text1'>Assured Profits, 100% Financial Security: Risk-Free Real Estate Success!</p>
            </div>
          </div>
        </div>

        <div className='relative'>
          <div className='d-flex'>
            <div className={`num_circle ${activeIndex === 2 ? 'active' : ''}`}>
              <p className='num_text'>3</p>
            
            </div>
          
            <div className='text-content'>
              <h3 className='whyheading1'>AssureVest</h3>
              <p className='why_text1'>ROI Insurance: Safeguarding Your Investment with Unmatched Assurance</p>
            </div>
          </div>
        </div>
      </div>
      <div className='relative whyek_div'>
        <img className='why_imgdiv' src={currentImage} alt='Why Ekakshar' loading='lazy' />
      </div>
    </div>
    </div>
  );
};

export default HomeWhy;