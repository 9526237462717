import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./component/Navbar/Navbar";
import Home from "./component/Home/Home";
import About from "./component/About/About";
import Project from "./component/Project/Project";
import "./App.css";
import Contact from "./component/Contact/Contact";
import Gallery from "./component/Gallery/Gallery";
import ScrollTop from "./ScrollTop";
import Shreeshyam from "./component/Projectinnerpages/Shreeshyam";
import Aerocity from "./component/Projectinnerpages/Aerocity";
import BlissValley from "./component/Projectinnerpages/BlissValley";
import Footer from "./component/Footer/Footer";
import Faq from "./component/Faq/Faq";
import PrivacyStatement from "./Privacy/PrivacyStatement";
import SlideoutPage from "./Slideout/SlideoutPage";
import Team from "./component/Team/Team";
import Blogs from "./component/Blogs/Blogs";
import Blogspage1 from "./component/Blogs/Blogspage1";
import Blogspage2 from "./component/Blogs/Blogspage2";
import Blogspage3 from "./component/Blogs/Blogspage3";
import Popupcontactform from "./component/Popup/Popupcontactform";

const App = () => {
  const location = useLocation();
  const [showHomeComponents, setShowHomeComponents] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") {
    
      const isReload = performance.getEntriesByType("navigation")[0].type === "reload";

      if (isReload) {
        setShowHomeComponents(true); 
      }
    }
  }, [location.pathname]);

  return (
    <div className="App">
      {showHomeComponents && (
        <>
          <Popupcontactform />
          <SlideoutPage />
        </>
      )}
      
      <ScrollTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/project" element={<Project />} />
        <Route path="/Shree-Shyam-Township" element={<Shreeshyam />} />
        <Route path="/Shree-Shyam-Aerocity" element={<Aerocity />} />
        <Route path="/Bliss-Valley" element={<BlissValley />} />
        <Route path="/faqs" element={<Faq />} />
        <Route path="/privacystatement" element={<PrivacyStatement />} />
        <Route path="/Our-Team" element={<Team />} />
        <Route path="/Our-Blogs" element={<Blogs />} />
        <Route path="/Mansion-Global-Your-Gateway-to-the-World-of-Luxury-Real-Estate" element={<Blogspage1 />} />
        <Route path="/Real-Estate-investment-strategies" element={<Blogspage2 />} />
        <Route path="/Nurturing-Minds-for-a-Brighter-Future" element={<Blogspage3 />} />
      </Routes>
      <Footer />
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
