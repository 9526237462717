import React from 'react'
import "./About.css"
const Aboutsec2 = () => {
  return (
    <div className='homeabout_div'>
        <div className='backdiv'>
        <h1 className='abouthead1 center '>About Founder</h1>
            <div className='home_abtsec'>
            <div>
                    <img src='./leadimg2.webp' className='abtimg_div1' alt='rohitsirimg' loading='lazy'/>
                </div>
                <div className=''>
                    <p className='aboutext'>Born in Meerut in August 1990, Rohit Kumar Khari is a well-known personality in the real estate sector and the founder of Ekakshar Buildtech Pvt. Ltd.. Mr. Rohit started his career with AVJ Heights (AVJ Group) in 2013 after completing  his degree in Mechanical Engineering, and he remained there until 2017. He laid the groundwork for his future business endeavours here by gaining priceless experience and knowledge of the real estate industry. An important milestone in Mr. Rohit's career was reached in 2022 when he started his first solo project in Khatu Shyam Ji, Rajasthan. </p>
                    <p className='aboutext'>As the founder of Ekakshar Buildtech Pvt. Ltd., he has been instrumental in planning and executing major development projects, including Aero City in Rajasthan, and large-scale ventures in Dehradun, Ayodhya, Noida Extension, and Ranikhet, with a total project valuation of ₹3,000 crore. Mr. Rohit is known for his relentless work ethic, visionary leadership, and dedication to excellence. His ability to inspire his team, coupled with his strategic foresight, has made him a respected leader in the real estate sector, constantly pushing boundaries to shape the future of urban development.
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Aboutsec2