import React, { useState } from 'react';
import './Faqs.css';
import data from './Faqs.json';
import { MdKeyboardArrowDown } from 'react-icons/md';

const FaqPayment = () => {
  const [payAnswerIndex, setPayAnswerIndex] = useState(null);
  const [payAnswerIndex1, setPayAnswerIndex1] = useState(null);

  const toggleAnswer = (index) => {
    setPayAnswerIndex(payAnswerIndex === index ? null : index);
  };

  const toggleAnswer1 = (index) => {
    setPayAnswerIndex1(payAnswerIndex1 === index ? null : index);
  };

  return (
    <div className='upcoming_backdiv'>
      <div className='backdiv'>
        <h2 className='abouthead_white'>Payment Question</h2>
        <div className='faq_ques_grid'>
          <div>
            {data.PaymentQuestion1.map((NewData, index) => (
              <div key={index} className='mb-4'>
                <div className='payment_ques_div' onClick={() => toggleAnswer(index)} style={{cursor:"pointer"}}>
                  <p className='payques_text mt-2'>{NewData.ques}</p>
                  <MdKeyboardArrowDown
                    className={`black_arrow_down ${payAnswerIndex === index ? 'rotate' : ''}`}
                    
                  />
                </div>
                <div className={`ans_div ${payAnswerIndex === index ? 'open' : ''}`}>
                  <div className='p-3'>
                    <p className='ans_text'>{NewData.ans}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            {data.PaymentQuestion2.map((NewData, index) => (
              <div key={index} className='mb-4'>
                <div className='payment_ques_div'>
                  <p className='payques_text mt-2'>{NewData.ques}</p>
                  <MdKeyboardArrowDown
                    className={`black_arrow_down ${payAnswerIndex1 === index ? 'rotate' : ''}`}
                    onClick={() => toggleAnswer1(index)}
                  />
                </div>
                <div className={`ans_div ${payAnswerIndex1 === index ? 'open' : ''}`}>
                  <div className='p-3'>
                    <p className='ans_text'>{NewData.ans}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqPayment;