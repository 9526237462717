import React from 'react'
import './Faqs.css'
import data from './Faqs.json'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';

const FaqAdvantage = () => {
  return (
    <div className='faq_quesdiv'>
        <div className='backdiv'>
<h2 className='abouthead1'>The Advantages</h2>
<p className='adv_text'>We specialize in crafting high-quality homes designed for luxury and comfort.</p>
<Swiper
      slidesPerView={3}
      spaceBetween={40}
      modules={[Autoplay]} 
      breakpoints={{
        320: { 
          slidesPerView: 1,
          autoplay: { delay: 2500, disableOnInteraction: false },
        },
        480: { 
          slidesPerView: 1,
          autoplay: { delay: 2500, disableOnInteraction: false },
        },
        768: { 
          slidesPerView: 2,
          spaceBetween: 20,
          autoplay: true, 
        },
        992: { 
          slidesPerView: 2,
          spaceBetween: 20,
          autoplay: true, 
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40,
          autoplay: false,
        },
      }}
    >
<div className='advantage_grid'>
{data.Advantage.map((NewData, index) => {
        return(
            <SwiperSlide>
<div key={index} className='advantage_card'>
    <div className='d-flex align-items-center justify-content-center'>
<img className='adv_img' src={NewData.img} alt='ss'/>
</div>
<h4 className='adv_head'>{NewData.head}</h4>
<p className='adv_content'>{NewData.text}</p>
</div>
</SwiperSlide>
 )
})}
</div>
</Swiper>
        </div>
    </div>
  )
}

export default FaqAdvantage