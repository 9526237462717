import React, { useState } from 'react';
import './Faqs.css'
import data from './Faqs.json'
import { MdKeyboardArrowDown } from "react-icons/md";

const FaqQuestion = () => {
  const [visibleAnswerIndex, setVisibleAnswerIndex] = useState(null);

  const toggleAnswer = (index) => {
    setVisibleAnswerIndex(visibleAnswerIndex === index ? null : index);
  };

  return (
    <div className='faq_quesdiv'>
      <div className='backdiv'>
        <h1 className='abouthead1'>Popular Question</h1>
        <div className='faq_ques_grid'>
          <div>
            {data.PopularQuestion.map((NewData, index) => {
              return (
                <div key={index} className='mb-4'>
                  <div className='ques_div' onClick={() => toggleAnswer(index)} style={{cursor:"pointer"}}>
                    <p className='ques_text mt-2'>{NewData.ques}</p>
                    <MdKeyboardArrowDown 
                      className={`arrow_down ${visibleAnswerIndex === index ? 'rotate' : ''}`}
                    />
                  </div>
                  <div className={`ans_div ${visibleAnswerIndex === index ? 'open' : ''}`}>
                    <div className='p-3'>
                    <p className='ans_text'>{NewData.ans}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div>
            <img className='popular_img' src='./popularimg.webp' alt='ss'/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FaqQuestion;