import React from 'react'
import './Faqs.css'

const FaqHero = () => {
  return (
    <div className='faq_herodiv'>
        <div className='d-flex align-item-center justify-content-center'>
        {/* <h1  className='faqherotext '>Frequently Asked Questions</h1> */}
        </div>
    </div>
  )
}

export default FaqHero