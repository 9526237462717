import React from 'react'
import "./Team.css"
const Teamsec1 = () => {
  return (
    <div className='teamheroback contentbefore'>
       {/* <h1 className='projectherotext '>OUR TEAM</h1> */}
        </div>
  )
}

export default Teamsec1 