import React from 'react'
import '../Project.css'
import data from '../../Home/Home.json'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import { FaLocationDot } from "react-icons/fa6";
import { FaBuilding } from "react-icons/fa6";
import { FaTag } from "react-icons/fa";
import { FaFlag } from "react-icons/fa";
const Projects = () => {
  return (
    <div className='exclusivediv'>
      <div className='backdiv'>
        <h2 className='abouthead1'>Our Projects</h2>
        <div className=''>
          <Swiper
            slidesPerView={3}
            spaceBetween={20}
            modules={[Autoplay]}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            breakpoints={{
              1024: {
                slidesPerView: 3, 
              },
              768: {
                slidesPerView: 2,  
              },
              480: {
                slidesPerView: 1,  // 1 slide on mobile
              },
              320: {
                slidesPerView: 1,  // Ensure 1 slide on smaller devices
              },
            }}
          >
            <div className='project_grids2'>
              {data.UpcomingProject1.map((NewData, index) => (
                                <SwiperSlide key={index}>
                                <a href={NewData.link}>
                                  <div className='exlapcard'>
                                    <img className='lapeximg' src={NewData.img1} alt='pro' loading='lazy' />
                                    <div className='cardheight'><p className='cardname'>{NewData.name}</p>
                                    <hr className='homehr' />
                                    <div className='between1'>
                                      <p className='onrequest'><FaLocationDot />{NewData.address}</p>
                                      <p className='cardtype'><FaFlag /> {NewData.type}</p></div>
                                    </div>
                                  </div>
                                </a>
                              </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Projects;
