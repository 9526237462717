import React from 'react'
import '../Project.css'

const ProjectHero = () => {
  return (
      <div className='project_herodiv'>
   {/*  <h1 className='projectherotext '>PROJECT</h1> */}
     </div>
  )
}

export default ProjectHero