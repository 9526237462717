import React, { useEffect } from 'react';
import '../Home.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { IoIosArrowRoundForward } from "react-icons/io";

const HomeHero = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500, 
      easing: 'ease-in-out',
    });
  }, []);
  return (
    <div className='home_hero_maindiv'>
      <div className='d-flex align-items-center justify-content-center' >
  <div className='hero_section'>
    <div className='boxx_div'>
<div className='hero_boxdiv'>

</div>
<div className='hero_contdiv'>
<h1 data-aos="fade-left" className='hero_head'>Where every project is a promise of excellence.</h1>
</div>
  </div>
  <div className='hero_section1' data-aos="fade-left">
<p  className='hero_text1'>Ekakshar Build Tech is your trusted partner in innovative construction solutions, delivering high-quality, sustainable, and modern building projects tailored to your needs.</p>
<div >
<a href='/contact'><button className='hero_gett_btn'>Get in Touch <IoIosArrowRoundForward className='arrow-btn' /></button></a></div>
  </div>
  </div>
  </div>
    </div>
  )
}

export default HomeHero