import React from 'react';
import './About.css';


const Aboutsec3 = () => (
  <div className="upcoming_backdiv backdiv">
    <h2 className="abouthead_white">WHAT WE OFFER</h2>
    <div className="container ">
      <div className="twogrid flexii">
        <div className="mt-4">
          <p className="aboutext_white">
          We at Ekakshar Buildtech give a full living experience rather than just houses. From well-planned living areas to knowledgeable real estate advice, we make sure every step of your trip runs well. Our projects are ideally situated, expertly constructed, and furnished with contemporary conveniences. You gain more than simply a house when you work with us; you also get a wise investment and a solid basis for future success.We are dedicated to designing areas that fulfill your requirements and go above and beyond your expectations. Our goal is to make every resident comfortable, safe, and convenient. Our goal is to create homes that are both aesthetically pleasing and useful by fusing robust construction, creative design, and sustainable methods.</p>
        </div>
        <div className='relative mt-4'>
          <img src='./whatweoffer.webp' className='offerimg' alt='img' loading='lazy'/>
        </div>
      </div>
    </div>
  </div>
);

export default Aboutsec3;
