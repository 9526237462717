import React,{useEffect} from 'react'
import "./Inner.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
const BlissValley = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500, 
      easing: 'ease-in-out',
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>Buy Property in Dehradun | Ekakshar Buildtech</title>
        <meta
          name="description"
          content="Ekakshar Buildtech  offers premium real estate services to help you buy property in Dehradun, near Paonta Sahib Gurudwara. Your dream home awaits!
"
        />
        <meta property="og:title" content="Bliss Valley | Luxury Real Estate by Ekakshar Buildtech" />
        <meta property="og:description" content="Discover Bliss Valley, an exclusive luxury real estate project by Ekakshar Buildtech. Experience high-end living with modern amenities and exceptional design." />
        <meta property="og:image" content="https://ekaksharbuildtech.com/innerproject3.webp" />
        <meta property="og:url" content="https://ekaksharbuildtech.com/Bliss-Valley" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Ekakshar Buildtech" />
         <meta name="robots" content="index, follow"/>
        <link rel="canonical" href="https://ekaksharbuildtech.com/Bliss-Valley" />
      </Helmet>
    <div className='blissheroback contentbefore'>
    </div>
    <div className='container twogrid flexii mt-4 '>
    <div className='mt-3' data-aos="zoom-in" >
    <h1 className='firstprojecthead'>About The Project</h1>
        <p className='aboutext'><b>Bliss Valley</b> in Dehradun offers a perfect blend of spiritual serenity and modern living, making it an ideal place to buy property. Spread across spacious plots ranging from 125 to 500 square yards, this unique residential community features the <b>Shri Radha Krishna Temple</b> at its heart, providing a peaceful spiritual haven for residents. The community also boasts a <b>Spiritual Convention Center</b>, perfect for yoga, meditation, and wellness activities. Along with its serene atmosphere, <b>Bliss Valley</b> is equipped with modern amenities like a <b>clubhouse, solar-powered street lights</b>, an <b>EV charging station</b>, and <b>rainwater harvesting systems</b>, making it both eco-friendly and contemporary. Living in <b>Bliss Valley</b> means enjoying an active lifestyle with access to a variety of recreational facilities such as a <b>mini golf park, badminton court, billiards table, skating rink</b>, and even a <b>paintball arena</b>. This vibrant community offers the perfect balance between leisure and peace, all set against the backdrop of Dehradun's natural beauty. With its focus on sustainability and comfort, <b>Bliss Valley</b> is the perfect place to <b>buy property in Dehradun</b>—a sanctuary where modern luxury and spiritual peace come together.</p>
        </div>
    <div className='mt-3'>
        <img src='/Blisssitelayout1.jpg' className='projectimg1' alt='bliss'/>
    </div>
    </div>
    <div className='container twogrid '>
    <div className='mt-3'>
        <img src='/Blissmainimg2.jpg' className='projectimg1' alt='bliss'/>
    </div>
    <div className='mt-3' data-aos="zoom-in" >
        <h2 className='firstprojecthead'>Creating Spaces for Better Living</h2>
        <p className='aboutext'>At Bliss Valley, we offer a variety of plot options designed to fulfill your unique vision and aspirations. Whether you desire a charming space for a cozy retreat or a spacious oasis, our selection caters to every budget and dream. For those seeking a luxurious getaway, explore our expansive farmhouse plots that invite you to create your perfect haven. Surrounded by the breathtaking natural beauty of Dehradun, these meticulously planned plots give you the freedom to shape your dream home.</p>
        <p className='aboutext'>In Dehradun Bliss Valley, patriotism isn't just a sentiment; it's a way of life. A majestic Indian flag, standing tall at the heart of the township, serves as a constant reminder of the values we cherish. This symbol of national pride creates an atmosphere of unity, inspiring residents to live with purpose and honor. Here, the spirit of India thrives, ensuring that every day is imbued with a sense of belonging and patriotic pride.</p>
    </div>
    </div>
    <div className='upcoming_backdiv mt-5' >
        <h4 className='whitetext'>Prime Location Benefits</h4>
        <div className="mobile-swiper container">
          <Swiper
          modules={Autoplay}
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false,  }}
          >
            <SwiperSlide><img src='/Aerocity-info7.webp' className='projectswiperimg' alt='projectimg'/></SwiperSlide>
            <SwiperSlide><img src='/Aerocity-info8.webp' className='projectswiperimg' alt='projectimg'/></SwiperSlide>
            <SwiperSlide><img src='/Aerocity-info9.webp' className='projectswiperimg' alt='projectimg'/></SwiperSlide>
          </Swiper>
        </div>
        <div className='threegrid container mt-3'>
            <img src='/Aerocity-info7.webp' className='projectswiperimg' alt='projectimg'/>
            <img src='/Aerocity-info8.webp' className='projectswiperimg' alt='projectimg'/>
            <img src='/Aerocity-info9.webp' className='projectswiperimg' alt='projectimg'/>
        </div>
    </div>
    <div className='blueback'>
    <div className='container'>
        <h3 className='projecthead'>Project Featuring</h3>
    <ul class="custom-list" data-aos="fade-up">
  <li>A modern library providing a peaceful space for reading.</li>
  <li>A blue water swimming pool for relaxation and recreation.</li>
  <li>A stunning fish aquarium adding a touch of aquatic beauty.</li>
  <li>A well-equipped gymnasium to keep you fit and active.</li>
  <li>A charming gazebo perfect for outdoor gatherings and relaxation.</li>
  <li>An amphitheatre for live performances and community events.</li>
  <li>A musical fountain and an open-air movie theatre for entertainment.</li>
  <li>Beautiful green areas including a greenbelt, flower valley, and party lawns, along with a lively shopping center.</li>
</ul>
        </div>
        </div>
        <div className=' mt-3' >
        <h4 className='blacktext'>Special Features</h4>
        <div className="mobile-swiper container">
          <Swiper
          modules={Autoplay}
            spaceBetween={10}
            slidesPerView={2}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false,  }}
          >
            <SwiperSlide><div className="carding">
   <div className="card1" >
    <p className="small">Enhanced security features with surveillance and gated access.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div></SwiperSlide>
            <SwiperSlide><div className="carding">
   <div className="card1" >
    <p className="small">On-site stores, cafes, and essential service outlets.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div></SwiperSlide>
            <SwiperSlide><div className="carding">
   <div className="card1" >
    <p className="small">Flexible workspaces equipped with high-speed internet.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div></SwiperSlide>
            <SwiperSlide><div className="carding">
   <div className="card1" > 
    <p className="small">Meeting rooms with video conferencing capabilities.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className='fourgrid1 container mt-3'>
<div className="carding">
   <div className="card1" >
    <p className="small">Enhanced security features with surveillance and gated access.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div>
<div className="carding">
   <div className="card1" >
    <p className="small">On-site stores, cafes, and essential service outlets.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div>
<div className="carding">
   <div className="card1" >
    <p className="small">Flexible workspaces equipped with high-speed internet.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div>
<div className="carding">
   <div className="card1" > 
    <p className="small">Meeting rooms with video conferencing capabilities.</p>
    <div className="go-corner" >
      <div className="go-arrow">
        →
      </div>
    </div>
  </div>
</div>
        </div>
    </div>
    </div>
  )
}

export default BlissValley