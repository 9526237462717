import React from 'react'
import './Footer.css'
// import { FaPhoneAlt } from "react-icons/fa";
// import { IoMdMail } from "react-icons/io";
// import { FaLocationDot } from "react-icons/fa6";
import { IoLogoFacebook } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { BsTwitterX } from "react-icons/bs";
// import { FaArrowRight } from "react-icons/fa6";
const Footer = () => {
  return (
    <div className='footer_div'>
      <div className='lapfooterview container'>
      <div className='footergrid1'>
        <div className='mobilepadd container'>
            <h4 className='goldfont'>Ekakshar Buildtech Pvt.Ltd</h4>
          <p className=' whitefont'>Ekakshar Buildtech is a leading real estate development company specializing in crafting premium residential spaces.</p>
        </div>
        <div className=' container  mobilepadd '>
          <h5 className='goldfont'>Other Pages</h5>
          <a href='/about-us'><p className='footer-link'>About Ekakshar</p></a>
          <a href='/project'><p className='footer-link'>Project</p> </a>
          <a href='/Contact'><p className='footer-link'>Contact us</p></a>
        </div>
        <div className=' container mobilepadd '>
          <h5 className='goldfont mar-top'>Quick Links</h5>
          <a href='/faqs'><p className='footer-link'>FAQ</p></a>
          <a href='/privacystatement' target='blank'><p className='footer-link'>Privacy Policy</p></a>
        </div>
        <hr className='new2'/>
        <div className=' '>
        <div className='container'>
            <h4 className='goldfont mar-top'>Connect With Us</h4>
            <a href="mailto:info@ekaksharbuildtech.com" target='blank' ><p className="footer-link1 mt-3">info@ekaksharbuildtech.com</p></a>
            <a href="tel:+917042005600" target='blank' > <p className=" footer-link1 mt-3">+91 70420 05600</p></a> 
          </div>
          <hr className='new2'/>
          <div className='container mt-3'>
          <h5 className='goldfont '>Follow us</h5>
          <div className='footersocialgrid mt-4'>
          <div className='facebookcircleback'><a href='https://www.facebook.com/EkaksharBuildtech' target='blank'><IoLogoFacebook className='facbeookicon'/></a></div>
          <div className='linkedinback'><a href='https://www.linkedin.com/company/ekaksharbuildtech/' target='blank'><FaLinkedin className='footericon1'/></a></div>
          <div className='instagramback'><a href='https://www.instagram.com/ekakshar_buildtech/' target='blank'><AiFillInstagram className='footericon1'/></a></div>
          <div className='twitterback'><a href='https://x.com/EkaksharBuilder' target='blank'><BsTwitterX className='footericon1'/></a></div>
          </div>
          </div>
    
        </div>
      </div>
      </div>
      <div className='mobfooterview'>
      <div className='footergrid1'>
        <div className='mobilepadd container'>
            <h4 className='goldfont'>Ekakshar Buildtech Pvt.Ltd</h4>
          <p className=' whitefont'>Ekakshar Buildtech is a leading real estate development company specializing in crafting premium residential spaces.</p>
        </div>
        <div className=' container  mobilepadd '>
          <h5 className='goldfont'>Other Pages</h5>
          <a href='/about-us'><p className='footer-link'>About Ekakshar</p></a>
          <a href='/project'><p className='footer-link'>Project</p> </a>
          <a href='/Contact'><p className='footer-link'>Contact us</p></a>
        </div>
        <div className=' container mobilepadd '>
          <h5 className='goldfont mar-top'>Quick Links</h5>
          <a href='/faqs'><p className='footer-link'>FAQ</p></a>
          <a href='/privacystatement' target='blank'><p className='footer-link'>Privacy Policy</p></a>
        </div>
        <hr className='new2'/>
        <div className=' '>
        <div className='container'>
            <h4 className='goldfont mar-top'>Connect With Us</h4>
            <a href="mailto:info@ekaksharbuildtech.com" target='blank' ><p className="footer-link1 mt-3">info@ekaksharbuildtech.com</p></a>
            <a href="tel:+917042005600" target='blank' > <p className=" footer-link1 mt-3">+91 70420 05600</p></a> 
          </div>
          <hr className='new2'/>
          <div className='container mt-3'>
          <h5 className='goldfont '>Follow us</h5>
          <div className='footersocialgrid mt-4'>
          <div className='facebookcircleback'><a href='https://www.facebook.com/EkaksharBuildtech' target='blank'><IoLogoFacebook className='facbeookicon'/></a></div>
          <div className='linkedinback'><a href='https://www.linkedin.com/company/ekaksharbuildtech/' target='blank'><FaLinkedin className='footericon1'/></a></div>
          <div className='instagramback'><a href='https://www.instagram.com/ekakshar_buildtech/' target='blank'><AiFillInstagram className='footericon1'/></a></div>
          <div className='twitterback'><a href='https://x.com/EkaksharBuilder' target='blank'><BsTwitterX className='footericon1'/></a></div>
          </div>
          </div>
    
        </div>
      </div>
      </div>
      <hr className='new1 mt-4'/>
      <div className='container'>
      <div className='center'><p className='goldfont'>© All Right Reserved By Ekakshar Tech Solutions.</p></div>
      </div>
    </div>
  )
}

export default Footer