import React, { useState } from 'react';
import './Navbar.css';
import { Link, useLocation } from 'react-router-dom';
import { RxHamburgerMenu, RxCross2 } from "react-icons/rx";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    // const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown
    const location = useLocation();

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    const closeNavbar = () => {
        setIsOpen(false);
    };



    const isProjectActive = ['/project', '/Jewar', '/Shree-Shyam-Township', '/Shree-Shyam-Aerocity', '/Bliss-Valley']
        .includes(location.pathname);

        const isBlogActive = ['/Our-Blogs', '/Mansion-Global-Your-Gateway-to-the-World-of-Luxury-Real-Estate', '/Real-Estate-investment-strategies', '/Nurturing-Minds-for-a-Brighter-Future']
        .includes(location.pathname);
    return (
        <nav className="d-flex align-items-center justify-content-center">
            <div className="navbar_container">
                <div>
                    <a href='/'><img src='./navlogo.webp' alt='logo' className="navbar-logo" loading='lazy' /></a>
                </div>
                <div className={isOpen ? 'nav-menu active' : 'nav-menu'}>
                    <li className="nav-item">
                        <Link
                            to="/"
                            className={`nav-links ${location.pathname === '/' ? 'active' : ''}`}
                            onClick={closeNavbar}
                        >
                            Home
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="/about-us"
                            className={`nav-links ${location.pathname === '/about-us' ? 'active' : ''}`}
                            onClick={closeNavbar}
                        >
                            About
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="/project"
                            className={`nav-links ${isProjectActive ? 'active' : ''}`}
                            onClick={closeNavbar}
                        >
                            Projects
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="/Our-Team"
                            className={`nav-links ${location.pathname === '/Our-Team' ? 'active' : ''}`}
                            onClick={closeNavbar}
                        >
                            Team
                        </Link>
                    </li>
                    {/* <li className="nav-item dropdown" onClick={toggleDropdown}>
                        <span className='nav-links arrow_down'>
                            Media <MdKeyboardArrowDown className={arrow_down ${isDropdownOpen ? 'rotate' : ''}} />
                        </span>
                        {isDropdownOpen && (
                            <div className='nav_dropdown'>
                                <div className='drop_div'>
                                    <li><Link to="/Our-Blogs" onClick={closeMenu} className="drop_item_last">Blogs</Link></li>
                                </div>
                                <div className='drop_div_last'>
                                    <li><Link to="/Our-News" onClick={closeMenu} className="drop_item_last">News</Link></li>
                                </div>
                            </div>
                        )}
                    </li> */}
                    <li className="nav-item">
                        <Link
                            to="/Our-Blogs"
                            className={`nav-links ${isBlogActive ? 'active' : ''}`}
                            onClick={closeNavbar}
                        >
                            Blogs
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="/faqs"
                            className={`nav-links ${location.pathname === '/faqs' ? 'active' : ''}`}
                            onClick={closeNavbar}
                        >
                            FAQ
                        </Link>
                    </li>
                    <li className="nav-item_contact">
                        <Link
                            to="/Contact"
                            className={`nav-links ${location.pathname === '/Contact' ? 'active' : ''}`}
                            onClick={closeNavbar}
                        >
                            Contact
                        </Link>
                    </li>
                    <Link to="/Contact">
                        <button className='nav_button'>CONTACT US</button>
                    </Link>
                </div>
                <div className="menu-icon" onClick={toggleNavbar}>
                    {isOpen ? <RxCross2 /> : <RxHamburgerMenu />}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;