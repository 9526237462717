import React from 'react'
import "../Blogs/Blogs.css"
import { FaCalendarAlt } from "react-icons/fa";
import { FaTag } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
const HomeBlogs = () => {
  return (
    <div className='container'>
        <h2 className='abouthead1 '>Blogs</h2>
        <div className=''>
    <div className='  mt-3'>
    <Swiper
              spaceBetween={40}  
              slidesPerView={3} 
              loop={true} 
              autoplay={{
                delay: 2000,  
                disableOnInteraction: false,  
              }}
              breakpoints={{
                1024: {
                  slidesPerView: 3, 
                },
                768: {
                  slidesPerView: 2,  
                },
                480: {
                  slidesPerView: 1,  // 1 slide on mobile
                },
                320: {
                  slidesPerView: 1,  // Ensure 1 slide on smaller devices
                },
              }}
              modules={[Autoplay]}  
              className="mySwiper"
            >
              <SwiperSlide>
    <a href='Mansion-Global-Your-Gateway-to-the-World-of-Luxury-Real-Estate' className='center'>
    <div className='blogscard '>
      <div className=''>
        <img src='./Blogsimg1.webp' className='blogsimg' alt='blogsimg' loading='lazy'/>
        <div className='cardheight'>
        <div className='d-flex'><p className='blogdatetext'><FaCalendarAlt className='blogicon'/> 06-09-2024 | </p> <p className='blogdatetext'><FaTag className='blogicon'/> Ekakshar Buildtech</p></div>
        <h5 className='blogsheading'>A Guide to Success with Agile...</h5>
       <p className='continueanchor'>Continue Reading</p></div>
        </div>
    </div>
    </a>
    </SwiperSlide>
    <SwiperSlide>
    <a href='Real-Estate-investment-strategies' className='center'>
    <div className='blogscard'>
    <div className=''>
        <img src='./Blogsimg2.webp' className='blogsimg' alt='blogsimg' loading='lazy'/>
        <div className='cardheight'>
        <div className='d-flex'><p className='blogdatetext'><FaCalendarAlt className='blogicon'/> 30-08-2024 | </p><p className='blogdatetext'><FaTag className='blogicon'/> Ekakshar Buildtech</p></div>
        <h5 className='blogsheading'>Real Estate investment strategies</h5>
       <p className='continueanchor'>Continue Reading</p></div>
        </div>
    </div>
    </a>
    </SwiperSlide>
    <SwiperSlide>
    <a href='Nurturing-Minds-for-a-Brighter-Future' className='center'>
    <div className='blogscard'>
    <div className=''>
        <img src='./blogsimg3.webp' className='blogsimg' alt='blogsimg' loading='lazy'/>
        <div className='cardheight'>
        <div className='d-flex'><p className='blogdatetext'><FaCalendarAlt className='blogicon'/> 16-08-2024 | </p><p className='blogdatetext'><FaTag className='blogicon'/> Ekakshar Buildtech</p></div>
        <h5 className='blogsheading'>Nurturing Minds for a Brighter Future</h5>
        <p className='continueanchor'>Continue Reading</p></div>
        </div>
    </div>
    </a>
    </SwiperSlide>
    </Swiper>
    </div>
    </div>
    </div>
  )
}

export default HomeBlogs