import React from 'react';
import data from './Team.json';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import HomeLeader from "../Home/HomeSection/HomeLeader"
const Teamsec2 = () => {
  return (
    <div className=" mt-4">
      <div className='container'>
      </div>
        <HomeLeader/>
      <div className="container ">
      <h1 className="abouthead1 ">Meet Our Team</h1>
      <p className="aboutext">
        At Ekakshar Buildtech Pvt.Ltd, we believe that success comes from collaboration, creativity, and a shared commitment to excellence. Our diverse and talented team brings together a wealth of experience, passion, and expertise to deliver exceptional results for our clients.
      </p>
        <Swiper
          spaceBetween={20}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 2000 }} 
          speed={800}
          breakpoints={{
            640: { 
              slidesPerView: 2,
            },
            1024: { 
              slidesPerView: 3,
            },
          }}
          modules={[Autoplay]}
        >
          {data.Leader.map((NewData, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="leaders_card mt-3">
                  <div className="leader_img_div">
                    <img className="leader_img" src={NewData.img} alt="Leader" loading='lazy' />
                    <div className="center">
                      <div className="leader_icons_div">
                        <a href={NewData.LinkedinLink} target="_blank" rel="noopener noreferrer">
                          <img className="social_icon" src={NewData.social1} alt="linkedin" loading='lazy' />
                        </a>
                        <a href={NewData.InstaLink} target="_blank" rel="noopener noreferrer">
                          <img className="social_icon" src={NewData.social2} alt="instagram"  loading='lazy'/>
                        </a>
                        <img className="social_icon" src={NewData.social4} alt="social4" loading='lazy' />
                      </div>
                    </div>
                  </div>
                  <div className="leader_overlay">
                    <div className="leader_text_div">
                      <h4 className="leader_head">{NewData.head}</h4>
                      <p className="leader_text">{NewData.txt}</p>
                      <div className="d-flex align-items-center justify-content-center">
                        <img className="logo_leader" src={NewData.logo} alt="logo" loading='lazy'/>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default Teamsec2;
