import React, { useState } from 'react';
import { IoMailOutline, IoCallOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { IoLogoFacebook } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { BsTwitterX } from "react-icons/bs";
import { LuYoutube } from "react-icons/lu";
import axios from 'axios';

const Contactform = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    budget: '',
    message: ''
  });
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://ekaksharbuildtech.com/contact_form_handler.php', formData, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      });
      setResponseMessage(response.data.message);
    } catch (error) {
      setResponseMessage("Error sending message. Please try again.");
    }
  };

  return (
    <div className='container'>
        <div className='blackdiv'>
            <div className='contactcontainer'>
                <div className='greycontainer'>
                    <h1 className='greyhead mt-3'>GET IN TOUCH </h1>
                    <div className='mt-5'>
                    <div className='d-flex '><div><CiLocationOn  className='contacticon'/></div><div><p className='greytext1'>7th Floor, Plot No-59, Riana Perito Tower , Sector-136, Noida 201305 </p></div></div>
                    <div className='d-flex '><IoMailOutline  className='contacticon'/><p className='greytext'>info@ekaksharbuildtech.com</p></div>
                    <div className='d-flex '><IoCallOutline className='contacticon'/><p className='greytext'> +91 - 7042005600</p></div>
                    </div>
                    <h3 className='greyhead'>OUR SOCIAL MEDIA </h3>
                    <div className='d-flex justify-content-center'>
                    <a href='https://www.facebook.com/EkaksharBuildtech' target='blank'><IoLogoFacebook className='contacticon1'/></a>
                    <a href='https://www.instagram.com/ekakshar_buildtech/' target='blank'><AiFillInstagram className='contacticon1'/></a>
                    <a href='https://www.youtube.com/@EkaksharBuildtech' target='blank'><LuYoutube className='contacticon1'/></a>
                    <a href='https://x.com/EkaksharBuilder' target='blank'><BsTwitterX className='contacticon1'/></a>
                    <a href='https://www.linkedin.com/company/ekaksharbuildtech/' target='blank'><FaLinkedin className='contacticon1'/></a>
                    </div>
                    <h6 className='greyhead mt-3'>Our Team will respond to your Query</h6>
                    <div className='center mt-3'><button className='greybutton'>+91 - 7042005600</button></div>
                </div>
                <div className='blackpadding'>
                <form onSubmit={handleSubmit}>
                    <h2 className='greyhead1 mt-2'>SEND US A MESSAGE</h2>
                    <input  type='text'
                name='name'
                placeholder='ENTER YOUR NAME'
                className='contactinput'
                value={formData.name}
                onChange={handleChange}/>
                    <input  type='email'
                name='email'
                placeholder='ENTER YOUR EMAIL'
                className='contactinput mt-3'
                value={formData.email}
                onChange={handleChange}/>
                    <input type='text'
                name='number'
                placeholder='ENTER YOUR NUMBER'
                className='contactinput mt-3'
                value={formData.number}
                onChange={handleChange}/>
                    <input type='text'
                name='budget'
                placeholder='ENTER YOUR BUDGET'
                className='contactinput mt-3'
                value={formData.budget}
                onChange={handleChange}/>
                    <textarea name='message'
                placeholder='ENTER YOUR MESSAGE'
                className='contactinput1 mt-3'
                value={formData.message}
                onChange={handleChange}/>
                    <div className='center'><button className='blackbutton mt-3'>SUBMIT</button></div>
                </form>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Contactform