import { Swiper, SwiperSlide } from 'swiper/react';
import '../Home.css';
import data from '../Home.json';
import 'swiper/css'; 
import { Autoplay } from 'swiper/modules';
import { useRef } from 'react';
import { FaFlag } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
const HomeExclusive = () => {
    const swiperRef = useRef(null);

  return (
    <div className='backdiv exclusivediv'>
    <h2 className='abouthead1'>Exclusive Projects</h2>
    <div className='homeproject_div'>
     <div className=''>
     <Swiper
              spaceBetween={20}  
              slidesPerView={3} 
              loop={true} 
              autoplay={{
                delay: 2000,  
                disableOnInteraction: false,  
              }}
              breakpoints={{
                1024: {
                  slidesPerView: 3, 
                },
                768: {
                  slidesPerView: 2,  
                },
                480: {
                  slidesPerView: 1,  // 1 slide on mobile
                },
                320: {
                  slidesPerView: 1,  // Ensure 1 slide on smaller devices
                },
              }}
              modules={[Autoplay]}  
              className="mySwiper"
            >
     {data.ExeProject.map((NewData, index) => (
      <SwiperSlide>
      <a key={index} href={NewData.link}>
      <div  className='exlapcard'>
        <img className='lapeximg' src={NewData.img1} alt='pro' loading='lazy'/>
        <div className='cardheight'><div className='between1'><p className='cardname'>{NewData.name}</p>
        <p className='onrequest '><b>₹</b> {NewData.price}</p></div>
        <hr className='homehr'/>
        <div className='between1'>
        <p className='onrequest'><FaLocationDot/>{NewData.address}</p>
        <p className='cardtype'><FaFlag/> {NewData.type}</p></div>
      </div></div>
      </a>
      </SwiperSlide>
      ))}
      </Swiper>
     </div>
      </div>
      <div className='center mt-3'> <a href="/project"> <button className="about_btn1"> View All </button> </a></div>
    </div>
  )
}

export default HomeExclusive