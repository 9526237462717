import React from 'react'
import '../Home.css'
import data from '../Home.json'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
const HomeLeader = () => {
  return (
    <div className='container'>
         <h2 className='abouthead1 mt-3'>Our Leaders</h2>
         <div className='leaderlapview'>
         <div className='leader_maindiv'>
         {data.Leader.map((NewData, index) => {
         return(
            <div key={index} className='leaders_card'>
                <div className='leader_img_div'>
                <img className='leader_img' src={NewData.img} alt='dd' loading='lazy'/>
                <div className='center'>
                <div className='leader_icons_div'>
                    <a href={NewData.LinkedinLink}  target="_blank" rel="noopener noreferrer">
<img className='social_icon' src={NewData.social1} alt='dd' loading='lazy'/></a>
<a href={NewData.InstaLink} target="_blank" rel="noopener noreferrer">
<img className='social_icon' src={NewData.social2} alt='dd' loading='lazy'/></a>
<img className='social_icon' src={NewData.social4} alt='dd' loading='lazy'/>
                </div>
                </div>
                </div>
          <div className='leader_overlay'>
            <div className='leader_text_div'>          
<h4 className='leader_head'>{NewData.head}</h4>
<p className='leader_text'>{NewData.txt}</p>
<div className='d-flex align-items-center justify-content-center'>
<img className='logo_leader' src={NewData.logo} alt='dd' loading='lazy'/>
</div>
</div>
          </div>
          </div>
             )
            })}
            </div>
            </div>
            <div className="mobile-swiper container">
          <Swiper
          modules={Autoplay}
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false,  }}
          >
            {data.Leader.map((NewData, index) => {
              return(
            <SwiperSlide><div className='leader_maindiv'>
            <div key={index} className='leaders_card'>
                <div className='leader_img_div'>
                <img className='leader_img' src={NewData.img} alt='dd' loading='lazy'/>
                <div className='center'>
                <div className='leader_icons_div'>
                    <a href={NewData.LinkedinLink}  target="_blank" rel="noopener noreferrer">
<img className='social_icon' src={NewData.social1} alt='dd' loading='lazy'/></a>
<a href={NewData.InstaLink} target="_blank" rel="noopener noreferrer">
<img className='social_icon' src={NewData.social2} alt='dd' loading='lazy'/></a>
<img className='social_icon' src={NewData.social4} alt='dd' loading='lazy'/>
                </div>
                </div>
                </div>
          <div className='leader_overlay'>
            <div className='leader_text_div'>          
<h4 className='leader_head'>{NewData.head}</h4>
<p className='leader_text'>{NewData.txt}</p>
<div className='d-flex align-items-center justify-content-center'>
<img className='logo_leader' src={NewData.logo} alt='dd' loading='lazy'/>
</div>
</div>
          </div>
          </div>
             
            </div></SwiperSlide>
              )
          })}
          </Swiper>
        </div>
         </div>
        
  
  )
}

export default HomeLeader